<template>

        <v-col align-start cols="12">
            <p>{{item.description}}</p>
            <p>{{item.postscript}}</p>
            <div>
                <strong>Event date(s): </strong>{{ formatDate(item.start) }} - {{ formatDate(item.stop) }}<br>
                <br>
            </div>
        </v-col>

</template>

<script>
import moment from 'moment'
export default {
    props: {
        item: {type: Object, required: true},
    },
    methods: {
        formatDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").format("M/D/YYYY ha")
        },
    }
}
</script>