<template>
    <v-container fluid >
        <v-row align-start justify-center row wrap>
            <v-col center cols="12">
                <EventInfo
                :item="item"
                />
            </v-col>
            <template v-if="userEvent">
            <v-col center cols="12"> 
                <div class="green text-center pa-2 ma-2">
                    <strong class="white--text text--bold-1">MY RESERVATION: </strong> 
                    <br /> <strong class="white--text text--lighten-1">{{userEventTime}}</strong> 
                    <br /> <span class="white--text text--lighten-1">(No email will be sent: this page serves as your reservation confirmation and ticket)</span> 
                </div>
            </v-col>
            </template>
            <template v-if="locked">
                <v-col center cols="12" class="ma-2"> 
                    <v-btn
                        outlined color="primary"
                        @click="changeReservation=true"
                    > Change My Reservation </v-btn>
                </v-col>
            </template>
            <template v-else>
                <v-col cols="12" class="ml-2 mr-2">
                    <div class="font-weight-bold">Choose a time:</div>
                    <v-radio-group  v-model="selectedSlot">
                    <v-radio
                        v-for="(option,n) in slotsArray"
                        :key="n"
                        :label="label(option)"
                        :value="option.name"
                        :disabled="!available(option)"
                    ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col center cols="12" pl-2 pr-2> 
                    <v-btn
                        color="primary" outlined
                        @click="updateReservation()"
                        :loading="loading"
                        :disabled="loading || userEvent == selectedSlot"
                    >{{ userEvent ? "Update": "Book"}} Reservation</v-btn>
                    <v-btn v-show="changeReservation"
                        color="primary" outlined
                        @click="changeReservation=false"
                    >Cancel Update</v-btn>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import EventInfo from '@/components/event/EventInfo'
import moment from 'moment'
export default {
    data () {
        return {
            alert: false,
            selectedSlot: null,
            showTitle: false,
            showDetails: false,
            changeReservation: false,
            loading: false,
        }
    },
    components:   {
        EventInfo
    },
    props: {
        item: {type: Object, required: true},
        disable: {type: Boolean, required: false},
        index: {type: Number, required: false}
    },
    computed: {
        locked () {
            return this.userEvent && !this.changeReservation
        },
        userEvent () { 
            let eventRef = "event-" + this.item.id
            let userEvent = this.$store.state.userInfo[eventRef]
            return userEvent
        },
        userEventTime () {
            let slot = this.item.slots[this.userEvent]
            return slot.section + ", " + slot.slot
        },
        slotsArray () {
            let arr = []
            for (const [key, value] of Object.entries(this.item.slots)) {
                arr.push({name: key, label: value.section + ", " + value.slot, limit: value.limit, booked: value.booked, sort: value.sort})
            }
            arr = arr.sort((a, b) => (a.sort > b.sort) ? 1 : -1)
            return arr
        },
    },
    methods: {
        formatDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").format("M/D/YYYY ha")
        },
        available (slot){
            return slot.booked < slot.limit
        },
        label (slot) {
            return slot.label + "\t(" + (slot.limit - slot.booked) + " remaining)"
        },
        selectUserEvent() {
            this.selectedSlot = this.userEvent
        },
        updateReservation() {
            //only proceed if the selected reservation is not the same as the existing reservation (if one exists; if not it is undefined)
            if(this.userEvent != this.selectedSlot) {
                this.loading = true
                let self = this
                let updateObject = {
                    ["slots." + self.selectedSlot + ".booked"]: global.FIREBASE.increment
                }
                if(this.userEvent != undefined){
                    updateObject = {
                        ["slots." + self.selectedSlot + ".booked"]: global.FIREBASE.increment,
                        ["slots." + self.userEvent + ".booked"]: global.FIREBASE.decrement
                    }
                }
                //let oldBookingRef = "slots." + self.userEvent + ".booked"
                //let newBookingRef = "slots." + self.selectedSlot + ".booked"
                global.FIREBASE.db.collection("events").doc(self.item.id)
                .update(updateObject)
                .then(function() {
                    let eventRef = "event-" + self.item.id
                    global.FIREBASE.db.collection("users").doc(global.FIREBASE.auth.currentUser.uid)
                    .update({
                        [eventRef]: self.selectedSlot
                    })
                    .then( () => {
                        console.log("successful event reservation update")
                        
                        self.selectUserEvent()
                        self.changeReservation = false
                        self.loading = false
                    })
                })
                .catch(function(error) {
                    console.error("Error updating event reservation: ", error)
                    self.loading = false
                })
            }
        },
    }
}
</script>

<style>
  .v-label {
    font-size: 10pt
  }
</style>